@use 'sass:map';
@import 'colors.scss';

$themes: (
  light: (
    // Text
    color-neutral-text-highest: $base-color-neutral-900,
    color-neutral-text-high: $base-color-neutral-600,
    color-neutral-text-low: $base-color-neutral-500,
    color-neutral-text-lowest: $base-color-neutral-400,
    // Background
    color-neutral-bg-highest: $base-color-neutral-300,
    color-neutral-bg-high: $base-color-neutral-200,
    color-neutral-bg-low: $base-color-neutral-100,
    color-neutral-bg-lowest: $base-color-neutral-000,
    // Primary Brand
    color-primary-brand-base: $base-color-primary-brand-400,
    color-primary-brand-high: $base-color-primary-brand-500,
    color-primary-brand-low: $base-color-primary-brand-200,
    color-primary-brand-lowest: $base-color-primary-brand-100,
    // Secondary Brand
    color-secondary-brand-base: $base-color-secondary-brand-400,
    color-secondary-brand-high: $base-color-secondary-brand-500,
    color-secondary-brand-low: $base-color-secondary-brand-200,
    color-secondary-brand-lowest: $base-color-secondary-brand-100,
    // Feedback
    // Error
    color-feedback-error-base: $base-color-red-400,
    color-feedback-error-high: $base-color-red-500,
    color-feedback-error-low: $base-color-red-200,
    color-feedback-error-lowest: $base-color-red-100,
    // Warning
    color-feedback-warning-base: $base-color-orange-400,
    color-feedback-warning-high: $base-color-orange-500,
    color-feedback-warning-low: $base-color-orange-200,
    color-feedback-warning-lowest: $base-color-orange-100,
    // Success
    color-feedback-success-base: $base-color-green-400,
    color-feedback-success-high: $base-color-green-500,
    color-feedback-success-low: $base-color-green-200,
    color-feedback-success-lowest: $base-color-green-100,
    // Highlight
    color-highlight-base: $base-color-blue-400,
    color-highlight-high: $base-color-blue-500,
    color-highlight-low: $base-color-blue-200,
    color-highlight-lowest: $base-color-blue-100,
    // Highlight Alt.
    color-highlight-alt-base: $base-color-pink-400,
    color-highlight-alt-high: $base-color-pink-500,
    color-highlight-alt-low: $base-color-pink-200,
    color-highlight-alt-lowest: $base-color-pink-100,
    // Extensions
    color-overlay: $base-color-overlay-light,
    color-brand-teal: $base-color-teal-400,
    color-brand-deep-blue: $base-color-deep-blue-400,
    // Fixed
    color-fixed-neutral-000: $base-color-neutral-000,
    color-fixed-highlight-100: $base-color-blue-100,
    color-fixed-highlight-200: $base-color-blue-200,
    color-fixed-highlight-500: $base-color-blue-500,
    color-callout-card-background: $base-color-secondary-brand-300,
    // Component-specific
    segmented-button-color-bg: $base-color-neutral-000,
    color-neutral-400-opacity-light: $base-color-neutral-400-opacity-light,
  ),
  dark: (
    // Text
    color-neutral-text-highest: $base-color-neutral-000,
    color-neutral-text-high: $base-color-neutral-300,
    color-neutral-text-low: $base-color-neutral-400,
    color-neutral-text-lowest: $base-color-neutral-500,
    // Background
    color-neutral-bg-highest: $base-color-neutral-600,
    color-neutral-bg-high: $base-color-neutral-700,
    color-neutral-bg-low: $base-color-neutral-800,
    color-neutral-bg-lowest: $base-color-neutral-900,
    // Primary Brand
    color-primary-brand-base: $base-color-secondary-brand-400,
    color-primary-brand-high: $base-color-secondary-brand-200,
    color-primary-brand-low: $base-color-secondary-brand-600,
    color-primary-brand-lowest: $base-color-secondary-brand-700,
    // Secondary Brand
    color-secondary-brand-base: $base-color-primary-brand-400,
    color-secondary-brand-high: $base-color-primary-brand-200,
    color-secondary-brand-low: $base-color-primary-brand-600,
    color-secondary-brand-lowest: $base-color-primary-brand-700,
    // Feedback
    // Error
    color-feedback-error-base: $base-color-red-300,
    color-feedback-error-high: $base-color-red-200,
    color-feedback-error-low: $base-color-red-600,
    color-feedback-error-lowest: $base-color-red-700,
    // Warning
    color-feedback-warning-base: $base-color-orange-300,
    color-feedback-warning-high: $base-color-orange-200,
    color-feedback-warning-low: $base-color-orange-600,
    color-feedback-warning-lowest: $base-color-orange-700,
    // Success
    color-feedback-success-base: $base-color-green-300,
    color-feedback-success-high: $base-color-green-200,
    color-feedback-success-low: $base-color-green-600,
    color-feedback-success-lowest: $base-color-green-700,
    // Highlight
    color-highlight-base: $base-color-blue-300,
    color-highlight-high: $base-color-blue-200,
    color-highlight-low: $base-color-blue-600,
    color-highlight-lowest: $base-color-blue-700,
    // Highlight Alt.
    color-highlight-alt-base: $base-color-pink-300,
    color-highlight-alt-high: $base-color-pink-200,
    color-highlight-alt-low: $base-color-pink-600,
    color-highlight-alt-lowest: $base-color-pink-700,
    // Extensions
    color-overlay: $base-color-overlay-dark,
    color-brand-teal: $base-color-teal-400,
    color-brand-deep-blue: $base-color-deep-blue-400,
    color-callout-card-background: $base-color-primary-brand-500,
    // Fixed
    color-fixed-neutral-000: $base-color-neutral-000,
    color-fixed-highlight-100: $base-color-blue-100,
    color-fixed-highlight-200: $base-color-blue-200,
    color-fixed-highlight-500: $base-color-blue-500,
    // Component-specific
    segmented-button-color-bg: $base-color-neutral-600,
    color-neutral-400-opacity-light: $base-color-neutral-400-opacity-light,
  ),
);

@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map.get(map.get($themes, $theme), '#{$key}');
        $theme-map: map.merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }

      @content;

      $theme-map: null !global;
    }
    :global(.theme--#{$theme}) & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map.get(map.get($themes, $theme), '#{$key}');
        $theme-map: map.merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }

      @content;

      $theme-map: null !global;
    }
  }
}
@function theme($key) {
  @return map.get($theme-map, $key);
}

// Variables for theme colors
$color-neutral-text-highest: 'color-neutral-text-highest';
$color-neutral-text-high: 'color-neutral-text-high';
$color-neutral-text-low: 'color-neutral-text-low';
$color-neutral-text-lowest: 'color-neutral-text-lowest';
$color-neutral-bg-highest: 'color-neutral-bg-highest';
$color-neutral-bg-high: 'color-neutral-bg-high';
$color-neutral-bg-low: 'color-neutral-bg-low';
$color-neutral-bg-lowest: 'color-neutral-bg-lowest';
$color-primary-brand-base: 'color-primary-brand-base';
$color-primary-brand-high: 'color-primary-brand-high';
$color-primary-brand-low: 'color-primary-brand-low';
$color-primary-brand-lowest: 'color-primary-brand-lowest';
$color-secondary-brand-base: 'color-secondary-brand-base';
$color-secondary-brand-high: 'color-secondary-brand-high';
$color-secondary-brand-low: 'color-secondary-brand-low';
$color-secondary-brand-lowest: 'color-secondary-brand-lowest';
$color-feedback-error-base: 'color-feedback-error-base';
$color-feedback-error-high: 'color-feedback-error-high';
$color-feedback-error-low: 'color-feedback-error-low';
$color-feedback-error-lowest: 'color-feedback-error-lowest';
$color-feedback-warning-base: 'color-feedback-warning-base';
$color-feedback-warning-high: 'color-feedback-warning-high';
$color-feedback-warning-low: 'color-feedback-warning-low';
$color-feedback-warning-lowest: 'color-feedback-warning-lowest';
$color-feedback-success-base: 'color-feedback-success-base';
$color-feedback-success-high: 'color-feedback-success-high';
$color-feedback-success-low: 'color-feedback-success-low';
$color-feedback-success-lowest: 'color-feedback-success-lowest';
$color-highlight-base: 'color-highlight-base';
$color-highlight-high: 'color-highlight-high';
$color-highlight-low: 'color-highlight-low';
$color-highlight-lowest: 'color-highlight-lowest';
$color-highlight-alt-base: 'color-highlight-alt-base';
$color-highlight-alt-high: 'color-highlight-alt-high';
$color-highlight-alt-low: 'color-highlight-alt-low';
$color-highlight-alt-lowest: 'color-highlight-alt-lowest';
$color-fixed-neutral-000: 'color-fixed-neutral-000';
$color-fixed-highlight-100: 'color-fixed-highlight-100';
$color-fixed-highlight-200: 'color-fixed-highlight-200';
$color-fixed-highlight-500: 'color-fixed-highlight-500';
$color-overlay: 'color-overlay';
$color-brand-teal: 'color-brand-teal';
$color-brand-deep-blue: 'color-brand-deep-blue';
$segmented-button-color-bg: 'segmented-button-color-bg';
$color-callout-card-background: 'color-callout-card-background';
$color-neutral-400-opacity-light: 'color-neutral-400-opacity-light';
