@import '@styles/config.scss';

.wrapper {
  position: relative;
  min-height: 100vh;

  .image {
    object-fit: cover;
  }

  .content {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 8vh 0 1.4rem;
    text-align: center;

    .title {
      padding-bottom: 3.2rem;
      @include body-short-lg;
      @include themed {
        color: theme($color-neutral-bg-lowest);
      }

      .jungle {
        padding-bottom: 0.4rem;
        @include heading-sm;
      }
    }

    .logo {
      width: 27rem;
      height: 14rem;
      margin-bottom: 4.8rem;
      @include themed {
        fill: theme($color-neutral-bg-lowest);
      }

      @include website-sm-viewport {
        width: 24.5rem;
        height: 12.7rem;
      }
    }
  }
}

.visually-hidden {
  @include visually-hidden;
}
