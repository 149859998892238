//  Website specific tokens
@import 'border.scss';
@import 'colors.scss';
@import 'drop-shadow.scss';
@import 'typography.scss';

// Header size
$website-header-size: 7.2rem;

// Colors
$website-color-neutral-400-semi-opaque: rgba(#a2a4a7, 0.4);
$website-color-brand-teal-opacity-semi-opaque: #2f486f66;
$website-color-neutral-400-light: rgba(#a2a4a7, 0.2);
$website-color-primary-brand-600: #182b2b;
$website-color-sand-400: #beaa93;
$website-color-brand-teal-semi-opaque: rgb(47 72 111 / 40%);
$website-color-neutral-000-opacity-transparent: rgba(#ffffff14, 0.08);

// Typeset
@mixin website-heading-xxxl {
  font-family: $font-family-brand;
  font-weight: $font-weight-bold;
  font-size: $font-size-xxxl;
  letter-spacing: $letter-spacing-sm;
  line-height: 6rem;
}

@mixin website-heading-xxl {
  font-family: $font-family-brand;
  font-weight: $font-weight-bold;
  font-size: $font-size-xxl;
  letter-spacing: $letter-spacing-sm;
  line-height: 4rem;
}

@mixin website-heading-xl {
  font-family: $font-family-brand;
  font-weight: $font-weight-semibold;
  font-size: $font-size-xl;
  letter-spacing: $letter-spacing-sm;
  line-height: 3.6rem;
}

@mixin website-heading-lg {
  font-family: $font-family-brand;
  font-weight: $font-weight-bold;
  font-size: $font-size-lg;
  letter-spacing: $letter-spacing-md;
  line-height: 2.6rem;
}

@mixin website-heading-md {
  font-family: $font-family-brand;
  font-weight: $font-weight-bold;
  font-size: $font-size-md;
  letter-spacing: $letter-spacing-md;
  line-height: 2.4rem;
}

@mixin website-body-xl {
  font-family: $font-family-base;
  font-weight: $font-weight-regular;
  font-size: $font-size-xl;
  letter-spacing: $letter-spacing-lg;
  line-height: 3.6rem;
}

@mixin website-body-lg {
  font-family: $font-family-base;
  font-weight: $font-weight-regular;
  font-size: $font-size-lg;
  letter-spacing: $letter-spacing-lg;
  line-height: 3rem;
}

@mixin website-body-md {
  font-family: $font-family-base;
  font-weight: $font-weight-regular;
  font-size: $font-size-md;
  letter-spacing: $letter-spacing-md;
  line-height: 2.8rem;
}

@mixin website-subtitle-xl {
  font-family: $font-family-brand;
  font-weight: $font-weight-regular;
  font-size: $font-size-xl;
  letter-spacing: $letter-spacing-lg;
  line-height: 3.6rem;
}

@mixin website-subtitle-xxl {
  font-family: $font-family-brand;
  font-weight: $font-weight-regular;
  font-size: $font-size-xxl;
  letter-spacing: $letter-spacing-sm;
  line-height: 4rem;
}

@mixin website-max-width {
  max-width: 168rem;
}

// Media Queries
@mixin website-sm-viewport {
  @media (max-width: 703px) {
    @content;
  }
}

@mixin website-md-viewport {
  @media (min-width: 704px) and (max-width: 1279px) {
    @content;
  }
}

@mixin website-lg-viewport {
  @media (min-width: 1280px) and (max-width: 1679px) {
    @content;
  }
}

@mixin website-xl-viewport {
  @media (min-width: 1680px) {
    @content;
  }
}

@mixin website-media($keys...) {
  @each $key in $keys {
    @if $key == website-sm-viewport {
      @include website-sm-viewport {
        @content;
      }
    } @else if $key == website-md-viewport {
      @include website-md-viewport {
        @content;
      }
    } @else if $key == website-lg-viewport {
      @include website-lg-viewport {
        @content;
      }
    } @else if $key == website-xl-viewport {
      @include website-xl-viewport {
        @content;
      }
    }
  }
}
