@import 'border.scss';
@import 'colors.scss';
@import 'drop-shadow.scss';
@import 'typography.scss';

// Heading
@mixin heading-giant {
  font-family: $font-family-brand;
  font-weight: $font-weight-bold;
  font-size: $font-size-giant;
  letter-spacing: $letter-spacing-sm;
  line-height: 8.4rem;
}
@mixin heading-display {
  font-family: $font-family-brand;
  font-weight: $font-weight-bold;
  font-size: $font-size-display;
  letter-spacing: $letter-spacing-sm;
  line-height: 7.6rem;
}
@mixin heading-xxxl {
  font-family: $font-family-brand;
  font-weight: $font-weight-bold;
  font-size: $font-size-xxxl;
  letter-spacing: $letter-spacing-sm;
  line-height: 6rem;
}
@mixin heading-xxl {
  font-family: $font-family-brand;
  font-weight: $font-weight-bold;
  font-size: $font-size-xxl;
  letter-spacing: $letter-spacing-sm;
  line-height: 4rem;
}
@mixin heading-xl {
  font-family: $font-family-brand;
  font-weight: $font-weight-bold;
  font-size: $font-size-xl;
  letter-spacing: $letter-spacing-sm;
  line-height: 3rem;
}
@mixin heading-lg {
  font-family: $font-family-brand;
  font-weight: $font-weight-bold;
  font-size: $font-size-lg;
  letter-spacing: $letter-spacing-md;
  line-height: 2.6rem;
}
@mixin heading-md {
  font-family: $font-family-brand;
  font-weight: $font-weight-bold;
  font-size: $font-size-md;
  letter-spacing: $letter-spacing-md;
  line-height: 2.4rem;
}
@mixin heading-sm {
  font-family: $font-family-base;
  font-weight: $font-weight-bold;
  font-size: $font-size-sm;
  letter-spacing: $letter-spacing-md;
  line-height: 2.2rem;
}
@mixin heading-xs {
  font-family: $font-family-base;
  font-weight: $font-weight-semibold;
  font-size: $font-size-xs;
  letter-spacing: $letter-spacing-lg;
  line-height: 2rem;
}

// Subtitle
@mixin subtitle-lg {
  font-family: $font-family-base;
  font-weight: $font-weight-medium;
  font-size: $font-size-xs;
  letter-spacing: $letter-spacing-lg;
}

@mixin subtitle-short-lg {
  line-height: 2rem;
  @include subtitle-lg;
}

@mixin subtitle-long-lg {
  line-height: 2.4rem;
  @include subtitle-lg;
}

@mixin subtitle-sm {
  font-family: $font-family-base;
  font-weight: $font-weight-medium;
  font-size: $font-size-xxs;
  letter-spacing: $letter-spacing-lg;
}

@mixin subtitle-short-sm {
  line-height: 1.8rem;
  @include subtitle-sm;
}

@mixin subtitle-long-sm {
  line-height: 2rem;
  @include subtitle-sm;
}

// Body
@mixin body-short {
  font-family: $font-family-base;
  font-weight: $font-weight-regular;
}
@mixin body-long {
  font-family: $font-family-base;
  font-weight: $font-weight-regular;
}
@mixin body-lg {
  font-size: $font-size-sm;
  letter-spacing: $letter-spacing-lg;
}
@mixin body-md {
  font-family: $font-family-base;
  font-size: $font-size-xs;
  letter-spacing: $letter-spacing-lg;
}
@mixin body-sm {
  font-size: $font-size-xxs;
  letter-spacing: $letter-spacing-lg;
}
@mixin body-short-lg {
  @include body-short;
  @include body-lg;

  line-height: 2.2rem;
}
@mixin body-long-lg {
  @include body-long;
  @include body-lg;

  line-height: 2.8rem;
}
@mixin body-short-md {
  @include body-short;
  @include body-md;

  line-height: 2rem;
}
@mixin body-long-md {
  @include body-long;
  @include body-md;

  line-height: 2.4rem;
}
@mixin body-short-sm {
  @include body-short;
  @include body-sm;

  line-height: 1.8rem;
}
@mixin body-long-sm {
  @include body-long;
  @include body-sm;

  line-height: 2rem;
}

// Other
@mixin link {
  font-family: $font-family-base;
  font-weight: $font-weight-medium;
  font-size: $font-size-xs;
  letter-spacing: $letter-spacing-lg;
  line-height: 2rem;
  text-decoration: underline;
}

@mixin link-large {
  @include link;

  line-height: 2.8rem;
  font-size: $font-size-sm;
}
