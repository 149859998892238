/* stylelint-disable property-no-vendor-prefix */
@import '@styles/config.scss';

@mixin button-transition {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.link {
  padding: 0.8rem;
  border-radius: 8rem;
  text-decoration: none;
  outline-color: transparent;

  .content {
    display: grid;
    align-items: center;
    gap: 2.4rem;
    border-radius: 8rem;
    position: relative;
    @include button-transition;
  }

  .arrow-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 8rem;
  }

  .button-label {
    z-index: 2;
  }

  // Size
  &.large {
    @include heading-lg;

    .content {
      padding-left: 2.4rem;
      grid-template-columns: 1fr 5.6rem;
      height: 5.6rem;
    }

    .arrow-wrapper {
      height: 5.6rem;
      width: 5.6rem;
      top: 0;
      right: 0;
      padding-right: 2rem;
      transition: width 0.3s ease-in-out;

      .icon {
        height: 1.5rem;
        width: 1.6rem;
      }
    }
  }

  &.medium {
    @include heading-md;

    .content {
      padding-left: 1.6rem;
      grid-template-columns: 1fr 4rem;
      height: 4rem;
    }

    .arrow-wrapper {
      height: 4rem;
      width: 4rem;
      top: 0;
      right: 0;
      padding-right: 1.3rem;
      transition: width 0.3s ease-in-out;

      .icon {
        height: 1.2rem;
        width: 1.3rem;
      }
    }
  }

  &:hover,
  &:focus {
    .content {
      gap: 4rem;
      @include button-transition;
    }

    .arrow-wrapper {
      width: 100%;
    }
  }

  // theme
  &.highlight {
    @include themed {
      background-color: theme($color-primary-brand-high);
      color: theme($color-neutral-bg-low);
    }

    .arrow-wrapper {
      @include themed {
        background-color: theme($color-primary-brand-base);
      }

      .icon {
        @include themed {
          fill: theme($color-neutral-bg-low);
        }
      }
    }

    &:hover,
    &:focus {
      @include themed {
        background-color: theme($color-callout-card-background);
        color: theme($color-primary-brand-base);
      }

      .content {
        .arrow-wrapper {
          @include themed {
            background-color: theme($color-secondary-brand-base);
          }

          .icon {
            @include themed {
              fill: theme($color-primary-brand-base);
            }
          }
        }
      }
    }
  }

  &.primary {
    @include themed {
      background-color: theme($color-primary-brand-high);
      color: theme($color-neutral-bg-low);
    }

    .arrow-wrapper {
      @include themed {
        background-color: theme($color-primary-brand-base);
      }

      .icon {
        @include themed {
          fill: theme($color-neutral-bg-low);
        }
      }
    }
  }

  &.secondary {
    @include themed {
      background-color: theme($color-callout-card-background);
      color: theme($color-primary-brand-base);
    }

    .arrow-wrapper {
      @include themed {
        background-color: theme($color-secondary-brand-base);
      }

      .icon {
        @include themed {
          fill: theme($color-primary-brand-base);
        }
      }
    }
  }

  &.white {
    @include themed {
      background-color: theme($color-neutral-bg-lowest);
      color: theme($color-primary-brand-base);
    }

    .arrow-wrapper {
      @include themed {
        background-color: theme($color-primary-brand-lowest);
      }

      .icon {
        @include themed {
          fill: theme($color-primary-brand-base);
        }
      }
    }
  }

  &.opacity {
    background-color: $website-color-neutral-400-semi-opaque;
    padding: 0.6rem;

    @include themed {
      color: theme($color-neutral-bg-lowest);
      border: $border-width-thick solid theme($color-neutral-text-lowest);
    }

    .arrow-wrapper {
      background-color: $website-color-neutral-400-light;

      .icon {
        @include themed {
          fill: theme($color-neutral-bg-lowest);
        }
      }
    }

    &:hover,
    &:focus {
      @include themed {
        color: theme($color-primary-brand-base);
        background-color: theme($color-callout-card-background);
        border-color: transparent;
      }

      .content {
        .arrow-wrapper {
          @include themed {
            background-color: theme($color-secondary-brand-base);
          }

          .icon {
            @include themed {
              fill: theme($color-primary-brand-base);
            }
          }
        }
      }
    }
  }
}
