// Font Family
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@400;500;600;700&family=Inter:wght@400;500;600;700&display=swap');

$font-family-base: 'Inter';
$font-family-brand: 'Sora';

// Font Weight
$font-weight-bold: 700;
$font-weight-semibold: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;

// Font Size
$font-size-xxs: 1.2rem;
$font-size-xs: 1.4rem;
$font-size-sm: 1.6rem;
$font-size-md: 1.8rem;
$font-size-lg: 2rem;
$font-size-xl: 2.4rem;
$font-size-xxl: 3.2rem;
$font-size-xxxl: 4.8rem;
$font-size-display: 6.4rem;
$font-size-giant: 8rem;

// Letter Spacing
$letter-spacing-sm: -0.04rem;
$letter-spacing-md: -0.02rem;
$letter-spacing-lg: 0;

// Line Height
$line-height-default: 100%;
$line-height-sm: 124%;
$line-height-md: 140%;
$line-height-lg: 172%;
$line-height-xl: 200%;
